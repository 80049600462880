import { LiveTvOutlined } from "@mui/icons-material";
import { update } from "lodash";

export const SERVER_URL = process.env.SK_SKCORE_SERVERURL;
export const GOOGLE_CLIENT_ID = process.env.SK_GOOGLE_AUDWEB;
export const S3_BUCKET = process.env.SK_AWS_BUCKETNAME;
export const APPLE_CLIENT_ID = process.env.SK_APPLE_CLIENTID;
export const APPLE_REDIRECT_URI = process.env.SK_APPLE_REDIRECT_URI;
export const OBS_SERVER_URL = process.env.SK_OBS_SERVERURL;
export const OBS_CHANNEL_URL = process.env.SK_OBS_CHANNELURL;

export const API = {
  //Auth
  googleLogin: `${SERVER_URL}/auth/google/web`,
  appleLogin: `${SERVER_URL}/auth/apple/web`,
  testUserLogin: `${SERVER_URL}/auth/login/test`,
  addUsersToStream: `${SERVER_URL}/auth/addUsersToStream`,
  INVITE_COLLABORATOR: `${SERVER_URL}/auth/invite/collaborator`,
  INVITE_ROYALTIES: `${SERVER_URL}/auth/invite/royalty`,
  // USER
  FUNDLINK: `${SERVER_URL}/payment/moonpay/create`,
  PAYPALLINK: `${SERVER_URL}/payment/paypal/order`,
  PAYPALLCAPTURE: `${SERVER_URL}/payment/paypal/capture?`,
  PAYPALLCANCEL: `${SERVER_URL}/payment/paypal/cancel/order`,

  SEARCH_USER: `${SERVER_URL}/user/search?keyword=`,
  FEATURE_USER: `${SERVER_URL}/user/list/featured?`,
  FEATURE_ARTIST: `${SERVER_URL}/user/featured/list?`,
  ADD_TOP_MUSIC: `${SERVER_URL}/user/top/music`,
  FLAG_USER: `${SERVER_URL}/user/flag/asset`,
  ATTRIBUTES: `${SERVER_URL}/user/attributes`,
  //Music
  UPLOAD_MUSIC: `${SERVER_URL}/music/upload`,
  MY_MUSIC: `${SERVER_URL}/music/all?`,
  FEATURE_MUSIC: `${SERVER_URL}/music/featured/list?`,
  SEARCH_MUSIC: `${SERVER_URL}/music/search?keyword=`,
  MUSIC_DETAIL: `${SERVER_URL}/music/details?_id=`,
  ALL_ARTIST_MUSIC: `${SERVER_URL}/music/artist/list`,
  INDIVIDUAL_SONG_TIPPER: `${SERVER_URL}/music/toptipper?`,
  //Albums
  MY_ALBUM: `${SERVER_URL}/album/user?id=`,
  CREATE_ALBUM: `${SERVER_URL}/album/create`,
  DELETE_ALBUM: `${SERVER_URL}/album/delete?_id=`,
  EDIT_ALBUM: `${SERVER_URL}/album/edit?_id=`,
  ADD_SONGS_TO_ALBUM: `${SERVER_URL}/album/add/music`,
  ALBUM_DETAILS: `${SERVER_URL}/album/details?`,
  DELETE_SONG_FROM_ALBUM: `${SERVER_URL}/album/remove/music`,

  //Playlist
  PLAYLIST: `${SERVER_URL}/playlist/user?id=`,
  MY_PLAYLIST: `${SERVER_URL}/playlist/user?`,
  CREATE_PLAYLIST: `${SERVER_URL}/playlist/create`,
  DELETE_PLAYLIST: `${SERVER_URL}/playlist/delete?_id=`,
  FEATURE_PLAYLIST: `${SERVER_URL}/playlist/featured/list?`,
  SEARCH_PLAYLIST: `${SERVER_URL}/playlist/search?keyword=`,
  PLAYLIST_DETAIL: `${SERVER_URL}/playlist/details?`,
  EDIT_PLAYLIST: `${SERVER_URL}/playlist/edit`,
  ADD_SONG_TO_PLAYLIST: `${SERVER_URL}/playlist/add/music`,
  REMOVE_SONG_FROM_PLAYLIST: `${SERVER_URL}/playlist/remove/music`,
  SAVE_PLAYLIST: `${SERVER_URL}/playlist/save/playlist`,
  GET_PLAYLIST_MUSIC: `${SERVER_URL}/playlist/music/all?`,
  TOPTEN_MY_PLAYLIST: `${SERVER_URL}/playlist/created?`,
  TOPTEN_BUY_ME_PLAYLIST: `${SERVER_URL}/playlist/purchased?`,
  //Genre
  GENRE_LIST: `${SERVER_URL}/music/genre/list`,
  //NFT
  CREATE_PLAYLIST_NFT: `${SERVER_URL}/nft/create/playlist`,
  CREATE_PLAYLIST_NFT_V4: `${SERVER_URL}/nft/create/playlist/v4`,
  // CREATE_MUSIC_NFT: `${SERVER_URL}/nft/create/audio`,
  CREATE_MUSIC_NFT: `${SERVER_URL}/nft/create/audio/v2`,
  CREATE_MUSIC_NFT_V4: `${SERVER_URL}/nft/create/audio/v4`,
  CREATE_MUSIC_NFT_V5: `${SERVER_URL}/nft/create/audio/v6`,

  MUSIC_NFT: `${SERVER_URL}/nft/market/place?type=music`,
  PLAYLIST_NFT: `${SERVER_URL}/nft/market/place?type=playlist`,
  NFT_BY_ID: `${SERVER_URL}/nft/details?nftId=`,
  NFT_BY_ID_V2: `${SERVER_URL}/nft/details/v2?nftId=`,
  PLAYLIST_NFT_BY_ID: `${SERVER_URL}/nft/playlist/details?nftId=`,
  BID_NFT: `${SERVER_URL}/nft/auction/bid`,
  BID_NFT_V2: `${SERVER_URL}/nft/auction/bid/v2`,

  SELL_NFT: `${SERVER_URL}/nft/sale/create`,
  SELL_NFT_V2: `${SERVER_URL}/nft/sale/create/v2`,

  BUY_NFT: `${SERVER_URL}/nft/sale/buy`,
  BUY_NFT_V2: `${SERVER_URL}/nft/sale/buy/v2`,
  BUY_MUSIC_NFT: `${SERVER_URL}/nft/music/buy`,
  AUCTION_NFT: `${SERVER_URL}/nft/auction/create`,
  AUCTION_NFT_V2: `${SERVER_URL}/nft/auction/create/v2`,
  NFT_OFFERS: `${SERVER_URL}/offer/nft/sale?id=`,
  CANCEL_SELL: `${SERVER_URL}/nft/sale/cancel`,
  CANCEL_SELL_V2: `${SERVER_URL}/nft/sale/cancel/v2`,
  CANCEL_AUCTION_NFT: `${SERVER_URL}/nft/auction/cancel`,
  CANCEL_AUCTION_NFT_V2: `${SERVER_URL}/nft/auction/cancel/v2`,

  //User
  USER_DETAILS: `${SERVER_URL}/user/details?_id=`,
  USER_PROFILE: `${SERVER_URL}/user/profile`,
  UPDATE_USER: `${SERVER_URL}/user/profile/update`,
  PROFILE_UPDATE: `${SERVER_URL}/user/profile/change`,
  USER_NFT_MUSIC: `${SERVER_URL}/nft/list/v3?`,
  USER_NFT_PLAYLIST: `${SERVER_URL}/nft/playlist/user?`,
  //Subscription
  UPDATE_SUBSCRIPTION: `${SERVER_URL}/subscription/artist/update`,
  GET_SUBSCRIPTIONS: `${SERVER_URL}/subscription/list?pageNo=1&pageSize=10&id=`,
  RENEWAL_SUBSCRIPTION_URL: `${SERVER_URL}/subscription/toggle/auto/renewal?autoRenewal=`,
  CREATE_SUBSCRIPTIONS: `${SERVER_URL}/subscription/create`,
  SUBSCRIBE_ARTIST: `${SERVER_URL}/subscription/artist`,
  UNSUBSCRIBE_ARTIST: `${SERVER_URL}/subscription/artist/cancel`,
  ASSET_PRIVACY: `${SERVER_URL}/subscription/assets/privacy`,
  UNLOCKED_ASSETS: `${SERVER_URL}/subscription/assets?`,
  PRIVATE_ASSETS: `${SERVER_URL}/subscription/user/assets?id=`,
  //FOLLOW
  FOLLOWERLIST: `${SERVER_URL}/follow/follower/list?user=`,
  FOLLOWINGLIST: `${SERVER_URL}/follow/following/list?user=`,
  UNFOLLOW: `${SERVER_URL}/follow/unfollow`,
  FOLLOW: `${SERVER_URL}/follow/user`,
  //NOtification
  NOTIFICATIONS: `${SERVER_URL}/notification/list`,
  MARK_SEEN: `${SERVER_URL}/notification/mark/read?`,
  MARK_ALL_SEEN: `${SERVER_URL}/notification/mark/readall?`,
  UNREAD: `${SERVER_URL}/notification/unread?pageNo=1&pageSize=13`,

  //WALLET
  BALANCE: `${SERVER_URL}/user/balance/v5`,
  TRANSACTIONS: `${SERVER_URL}/user/transactions?`,
  TOKEN_LIMIT_REQUEST: `${SERVER_URL}/request/increase/token/limit`,

  //Offboard
  OFFBOARD_TRANSFER: `${SERVER_URL}/offboard/transfer`,
  OFFBOARD_CASHOUT: `${SERVER_URL}/offboard/cashout`,
  OFFBOARD_CASHOUT_LIST: `${SERVER_URL}/offboard/cashout/list`,
  OFFBOARD_CASHOUT_SEND: `${SERVER_URL}/offboard/cashout/send`,

  //Payment
  TIP_ARTIST: `${SERVER_URL}/payment/tip`,
  TIP_PLAYLIST: `${SERVER_URL}/payment/tip/playlist`,
  //gamification
  CURATOR_DATA: `${SERVER_URL}/gamification/data?userid=`,
  CREATOR_DATA: `${SERVER_URL}/gamification/creator/data?userid=`,
  TOPTEN_LISTEN_PLAYLIST: `${SERVER_URL}/gamification/playlistsat/topten/listens/playlists`,
  TOPTEN_TIP_PLAYLIST: `${SERVER_URL}/gamification/playliststat/topten/tip/playlists?`,
  TOPTEN_SAVED_PLAYLIST: `${SERVER_URL}/gamification/playliststat/saved/playlists`,

  PLAYLIST_STATICS: `${SERVER_URL}/gamification/playliststat?`,
  //Analytics
  MUSIC_ANALYTICS: `${SERVER_URL}/analytics/music/v2`,
  LIFEIME_VIEW_ANALYTICS: `${SERVER_URL}/analytics/music/views/v2?range=`,
  LIFETIME_TIP_ANALYTICS: `${SERVER_URL}/analytics/music/tips/v2?range=`,
  LIFEIME_STREAM_ANALYTICS: `${SERVER_URL}/analytics/music/listens?range=`,
  LIFEIME_FOLLOWERS_ANALYTICS: `${SERVER_URL}/analytics/followers?range=`,
  MUSIC_VIEW_ANALYTICS: `${SERVER_URL}/analytics/singlemusic/views/v2?`,
  MUSIC_TIP_ANALYTICS: `${SERVER_URL}/analytics/singlemusic/tips/v2?`,
  MUSIC_STREAM_ANALYTICS: `${SERVER_URL}/analytics/singlemusic/listens?`,
  MUSIC_FOLLOW_ANALYTICS: `${SERVER_URL}/analytics/singlemusic/followers?`,
  AGE_GROUP: `${SERVER_URL}/analytics/age?`,
  COUNT: `${SERVER_URL}/analytics/count?`,

  //CURATOR
  LIFETIME_TIPS: `${SERVER_URL}/gamification/curator/tips?range=`,
  LIFETIME_LISTEN: `${SERVER_URL}/gamification/curator/listen?range=`,
  LIFETIME_SUBSCRIPTION: `${SERVER_URL}/gamification/curator/subscriptions?range=`,
  LIFETIME_OWNED: `${SERVER_URL}/gamification/curator/owned/nfts/?range=`,
  LIFETIME_PURCHASE: `${SERVER_URL}/gamification/curator/purchased/nfts?range=`,
  LIFETIME_PLAYLISTS: `${SERVER_URL}/gamification/curator/created/playlists?range=`,
  LIFETIME_STREAMS: `${SERVER_URL}/gamification/curator/streamed/playlists?range=`,
  CURATOR_TAB_DATA: `${SERVER_URL}/gamification/curator/counts?range=`,

  //Creator
  CREATOR_STREAMS: `${SERVER_URL}/gamification/creator/playlists/streams?range=`,
  SONG_RELEASED: `${SERVER_URL}/gamification/creator/released/songs?range=`,
  NFT_SOLD: `${SERVER_URL}/gamification/creator/nft/sold?range=`,
  PLAYLIST_ADD: `${SERVER_URL}/gamification/creator/playlist/adds?range=`,
  CREATOR_TAB_DATA: `${SERVER_URL}/gamification/creator/counts?range=`,
  //HomeScreen

  //Music
  MUSIC_ALL: `${SERVER_URL}/homescreen/music`,
  MUSIC_MOST_LISTEN: `${SERVER_URL}/homescreen/music/mostlisten`,
  MUSIC_MOST_STREAM_TODAY: `${SERVER_URL}/homescreen/music/moststreamed/today`,
  MUSIC_MOST_STREAM_WEEK: `${SERVER_URL}/homescreen/music/moststreamed/thisweek`,
  MUSIC_NEW_RELEASE: `${SERVER_URL}/homescreen/music/newrelease`,
  MUSIC_MOST_TIPPED: `${SERVER_URL}/homescreen/music/mosttipped`,
  //Nft
  NFT_ALL: `${SERVER_URL}/homescreen/nft`,
  MOSTEXPENSIVE: `${SERVER_URL}/homescreen/nft/mostexpensive`,
  PLAYLISTMOSTEXPENSIVE: `${SERVER_URL}/homescreen/nft/playlist/mostexpensive`,
  LEASTEXPENSIVE: `${SERVER_URL}/homescreen/nft/leastexpensive`,
  PLAYLISTLEASTEXPENSIVE: `${SERVER_URL}/homescreen/nft/playlist/leastexpensive`,
  OWNERPERCENTAGE: `${SERVER_URL}/homescreen/nft/owner/percentage`,
  ENDINGSOONAUCTION: `${SERVER_URL}/homescreen/nft/endingsoon`,
  GET_NFT_FRACTIONS: `${SERVER_URL}/fractions/available?musicId=`,
  GIFT_NFT_FRACTIONS: `${SERVER_URL}/fractions/gift`,
  ALL_NFT: `${SERVER_URL}/nft?`,
  GIFT_PLAYLIST_NFT: `${SERVER_URL}/fractions/gift/playlist`,
  NFT_RANK: `${SERVER_URL}/nft/owners?musicId=`,
  ACTIVE_BID: `${SERVER_URL}/nft/active?musicId=`,
  //PLAYLIST
  PLAYLIST_ALL: `${SERVER_URL}/homescreen/playlist`,
  PLAYLIST_MOST_TIPPED: `${SERVER_URL}/homescreen/playlist/mosttipped?`,
  PLAYLIST_STREAMED: `${SERVER_URL}/homescreen/playlist/moststream`,
  PLAYLIST_NEW_RELEASE: `${SERVER_URL}/homescreen/playlist/new/release`,
  PLAYLIST_STREAMED_LASTWEEK: `${SERVER_URL}/homescreen/playlist/lastweek/most/streamed`,
  PLAYLIST_MOST_STREAMED: `${SERVER_URL}/homescreen/playlist/today/most/streamed`,
  PLAYLIST_ALL_: `${SERVER_URL}/playlist/unread?createdBy=`,
  SORT_PLAYLIST_MUSIC: `${SERVER_URL}/playlist/update/order`,
  //Stream
  STREAM_ADD: `${SERVER_URL}/streaming/add`,

  //Public
  CHARGES: `${SERVER_URL}/public/charges`,
  //Banner
  BANNER: `${SERVER_URL}/user/media/banners`,
  //Chat
  GET_MESSAGES: `${SERVER_URL}/message/getall`,
  GET_ONLINE_USERS: `${SERVER_URL}/message/onlineusers`,
  GET_PLAYLIST_TOP_FIVE: `${SERVER_URL}/message/playlisttopfive?`,
  PLAYLIST_TOP_MOST_TIPPED: `${SERVER_URL}/homescreen/playlist/mosttipped?`,
  PLAYLIST_TOP_MOST_STREAMED: `${SERVER_URL}/homescreen/playlist/moststream?`,
  GET_TOP_CHATS: `${SERVER_URL}/message/toptenusers?userId=`,
  GET_PRIVATE_MESSAGE: `${SERVER_URL}/message/private?`,
  READ_MESSAGE: `${SERVER_URL}/message/mark/readall?`,
  GROUP_CHAT: `${SERVER_URL}/message/chatdata?userId=`,
  GROUP_CHAT_V2: `${SERVER_URL}/message/chatdata/v2?userId=`,
  GET_ROOM_ID: `${SERVER_URL}/message/check/room?`,
  GET_PLAYLIST_MESSAGE: `${SERVER_URL}/message/playlist?`,
  BLOCK_USER_CHAT: `${SERVER_URL}/message/block`,
  BLOCKER_USER: `${SERVER_URL}/message/block/check?roomId=`,
  //Auction house
  BID_LIST: `${SERVER_URL}/offer/bid?nftId=`,
  AUCTION_LIST: `${SERVER_URL}/offer/nft?nftId=`,
  BID_AND_AUCTIONS: `${SERVER_URL}/offer/list`,
  OFFERS: `${SERVER_URL}/offer/music/offer?id=`,
  ACTIVITY: `${SERVER_URL}/offer/music/activity?id=`,
  OFFERS_PLAYLIST: `${SERVER_URL}/offer/playlist/offer?id=`,
  ACTIVITY_PLAYLIST: `${SERVER_URL}/offer/playlist/activity?id=`,

  //Offers
  OFFERS_SALE: `${SERVER_URL}/offer/nft/sale?assetId=`,
  OFFERS_AUCTION: `${SERVER_URL}/offer/nft/auction?assetId=`,

  //Streaming
  TOP_TIPPERS: `${SERVER_URL}/livestream/mosttipped?channelName=`,
  LIVESTREAM: `${SERVER_URL}/livestream/live`,
  LAYOUT: `${SERVER_URL}/livestream/layout?`,
  LIVEEND: `${SERVER_URL}/livestream/end/host`,
  LIVESTART: `${SERVER_URL}/livestream/start/host`,
  //MATIC

  CREATE_MATIC: `${SERVER_URL}/user/wallet/create/v3`,
  GOAL: `${SERVER_URL}/playlist/goal?playlistId=`,

  //VIDEO
  DELETE_VIDEO: `${SERVER_URL}/studio/:studioId/video/:videoId`,
  UPDATEVIDEO: `${SERVER_URL}/studio/:studioId/video/:videoId`,

  VIDEO_USER: `${SERVER_URL}/studio/:studioId/videos`,
  UPVID: `${SERVER_URL}/studio/:studioId/video`,
  VIDEO: `${SERVER_URL}/user/videos`,
  DOWNLOAD_RECORDING: `${SERVER_URL}/livestream/download?key=`,
  RECORDINGS: `${SERVER_URL}/livestream/recording?`,
  DELETE_RECORDING: `${SERVER_URL}/livestream/recording/:recordingId`,
  UPDATE_RECORDING: `${SERVER_URL}/livestream/recording/:recordingId`,
  LANGUAGE: `${SERVER_URL}/init?`,
  UPDATE_GIFT_STATUS: `${SERVER_URL}/nft/opened`,
  ADD_COUNT: `${SERVER_URL}/public/config?`,
  ADD: `${SERVER_URL}/ad/ads?`,

  //ARtist profile
  TRENDING: `${SERVER_URL}/user/trending?artistId=`,
  TOP_LIST: `${SERVER_URL}/artist/content?`,
  MUSIC_LIST: `${SERVER_URL}/artist/music?`,

  // Artist Search
  SEARCH_ARTIST: `${SERVER_URL}/artist/search?keyword=`,

  // Elastic Search
  SEARCH: `${SERVER_URL}/search?keyword=`,

  // Schedule
  SCHEDULE_LIVESTREAM: `${SERVER_URL}/schedule/livestream`,
  SCHEDULE_LIVESTREAM_CANCEL: `${SERVER_URL}/schedule/livestream/cancel`,
  SCHEDULE_LIVESTREAMS: `${SERVER_URL}/schedule/livestreams?`,
  SCHEDULE_LIVESTREAMS_DATE: `${SERVER_URL}/schedule/livestreams/date?`,
  SCHEDULE_LIVESTREAMS_USER: `${SERVER_URL}/schedule/livestreams/user?`,

  // Dynamic Links
  CREATE_DYNAMIC_LINK: `${SERVER_URL}/dynamiclink/create`
};
